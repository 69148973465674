<template>
  <div class="dash">
    Choose album to edit or create new
  </div>
</template>

<script>
export default {
  name: 'dash'
}
</script>

<style lang="postcss" scoped>
  .dash {
    @apply p-7 text-2xl
  }
</style>
