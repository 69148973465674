import { render, staticRenderFns } from "./Dash.vue?vue&type=template&id=804e7590&scoped=true&"
import script from "./Dash.vue?vue&type=script&lang=js&"
export * from "./Dash.vue?vue&type=script&lang=js&"
import style0 from "./Dash.vue?vue&type=style&index=0&id=804e7590&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "804e7590",
  null
  
)

export default component.exports